$rootSelector: ":host";
@use "sass:map";

@use "@chladog/wemarry-shared/scss/reset";

@use "@chladog/wemarry-shared/scss/scrollbar";
@use "@angular/cdk/overlay-prebuilt.css";
@use "fonts";

@use "@angular/material" as mat;
@include mat.core();
$md-primary: (
  50: #e4e4e4,
  100: #bdbdbd,
  200: #a1a1a1,
  300: #7e7e7e,
  400: #6e6e6e,
  500: #5f5f5f,
  600: #505050,
  700: #404040,
  800: #313131,
  900: #222222,
  A100: #e8d5d5,
  A200: #c59292,
  A400: #8b6666,
  A700: #716666,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-accent: (
  50: #b1f9f4,
  100: #6af3eb,
  200: #36efe4,
  300: #11cdc2,
  400: #0eb0a7,
  500: #0c948c,
  600: #0a7871,
  700: #075b56,
  800: #053f3c,
  900: #032321,
  A100: #a0fff9,
  A200: #3afff3,
  A400: #00d3c7,
  A700: #09b0a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$svatba-web-ui-primary: mat.define-palette($md-primary);
$svatba-web-ui-accent: mat.define-palette($md-accent);

// The warn palette is optional (defaults to red).
$svatba-web-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$svatba-web-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $svatba-web-ui-primary,
      accent: $svatba-web-ui-accent,
      warn: $svatba-web-ui-warn,
    ),
  )
);

$foreground-color: #5f5f5f;
$color: map.get($svatba-web-ui-theme, color);
$foreground: map.get($color, foreground);
$foreground: map.merge(
  $foreground,
  (
    text: $foreground-color,
  )
);
$color: map.merge(
  $color,
  (
    foreground: $foreground,
  )
);
$theme: map.merge(
  $svatba-web-ui-theme,
  (
    color: $color,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.slider-theme($theme);
html {
  scroll-behavior: smooth;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

*,
*:before,
*:after {
  user-select: none;
}

wemarry-page {
  *,
  *:before,
  *:after {
    user-select: initial;
  }
}

// snack-bar-container.mat-snack-bar-container {
//   background-color: var(--snackbar-background, var(--color-body, rgba(#fff, .8)));
//   border-radius: 2px;
//   box-shadow: 0 0 .5rem rgba(#000, .5);
//   backdrop-filter: blur(3px);
//   transform: translateZ(0);
// }

body .mat-mdc-snack-bar-container .mdc-snackbar__surface {
  color: var(--color-text);
  --mdc-snackbar-container-color: rgba(255, 255, 255, 0.8);
  --mdc-snackbar-container-shape: var(--radius-2, 0.125rem);
  backdrop-filter: blur(3px);
  transform: translateZ(0);
}

body.rotation-active {
  cursor: url(https://wemarry.app/api-uploads/icons/sync.svg), cell;
}

@media print and (orientation: portrait) {
  @page {
    size: A4 portrait;
    margin: 0;
  }
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
}

@media print and (orientation: landscape) {
  @page {
    size: A4 landscape;
    margin: 0;
  }
  // html,
  // body,
  // wemarry-app {
  //   width: 297mm;
  //   height: 210mm;
  // }
}
